
import MediaOverview from "@/components/mediaHub/MediaOverview"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { Media } from "@evercam/shared/types/media"
import Vue from "vue"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"
import CountDownFunction from "@/components/CountDownFunction.vue"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default Vue.extend({
  components: {
    MediaOverview,
    CountDownFunction,
  },
  async asyncData({ params }) {
    const routeProjectExid = (
      camelizeKeys(params) as unknown as { projectExid: string }
    )?.projectExid
    let mediaItem = null as unknown as Media

    try {
      mediaItem = await EvercamApi.mediaHub.cShow(
        routeProjectExid,
        params.mediaExid
      )
    } catch (error) {
      console.error(error)
    }

    return {
      mediaItem,
    }
  },
  data() {
    return {
      mediaItem: null as unknown as Media,
    }
  },
  head() {
    return {
      title: `${
        useProjectStore().selectedProject?.name || "Evercam"
      } | ${this.$t("pages.media_hub")}`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  meta: {
    pageId: AnalyticsEventPageId.MediaOveriew,
    public: true,
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    getLinkPath() {
      return this.projectStore.selectedProject
        ? `${this.projectStore.projectRoute}/media-hub`
        : "/v2/projects"
    },
  },
})
