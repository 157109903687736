
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  name: "MediaUrlField",
  components: {
    CopyToClipboardBtn,
  },
  props: {
    url: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    format: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCopyUrl() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaCopyMediaLink, {
        type: this.type,
      })
    },
  },
}
