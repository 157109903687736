
import Vue from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default Vue.extend({
  name: "CountDownFunction",
  props: {
    description: {
      type: String,
      default: "",
    },
    redirectUrl: {
      type: String,
      default: null,
    },
    linkPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      coundown: 5,
      countdownInterval: null,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  mounted() {
    this.countdownFunc()
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval)
    }
  },
  methods: {
    countdownFunc() {
      this.countdownInterval = setInterval(() => {
        this.coundown--
        if (this.coundown == 0) {
          if (this.redirectUrl) {
            this.accountStore.redirectUrl = this.redirectUrl
          }
          clearInterval(this.countdownInterval)
          this.$router.push(this.linkPath)
        }
      }, 1000)
    },
  },
})
